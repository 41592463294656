.skills {
  width: 100vw;
  height: 100svh !important;
  display: flex;
}

.skills-left {
  flex: 0.5; /* Left side (icon cloud) takes up 50% */
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column; /* Stack items vertically */

}

.skills-right {
  flex: 0.5; /* Right side (text section) takes up 50% */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}

.skills-icon-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* Center the cloud and the note */
}

.skills-icon-cloud {
  width: 80%; /* Adjust size for larger screens */
  display: flex;
  justify-content: center;
}

.skills-title {
  font-size: 64px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #383839; /* Dark grey color */
  font-family: 'Permanent Marker', cursive; /* Use Permanent Marker font */
  text-align: left; /* Align text left */
  width: 100%; /* Full width */
}

.skills-description {
  font-size: 20px;
  line-height: 1.6;
  color: #383839; /* Set description text to the same dark grey color */
  font-family: 'Open Sans', sans-serif; /* More standard, legible font */
  text-align: left;
  padding-right: 100px;
}

.strong{
  font-weight: 700;
}

.skills-note {
  font-size: 16px;
  color: #383839; /* Same dark grey color */
  text-align: center; /* Center the note */
  margin-top: 10px; /* Add a little spacing from the icon cloud */
  font-family: 'Permanent Marker', cursive; /* Consistent font style */
  font-style: italic; /* Add italic style for emphasis */
}


@media (max-width: 1024px) {
  .skills-title {
    font-size: 48px;
  }
  .skills-description {
    font-size: 18px;
    padding-right: 50px !important;
  }
  .skills-icon-cloud{
    width: 80%;
  }
}


/* Responsive layout: stack title, cloud, description */
@media (max-width: 768px) {
  .skills {
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center everything */
    justify-content: center;
    padding: 20px;
  }

  .skills-title {
    font-size: 40px;
    text-align: center;
    margin-bottom: 40px;
  }

  .skills-icon-cloud {
    width: 60%;
    margin-bottom: 40px;
  }

  .skills-description {
    width: 90%;
    text-align: left;
    font-size: 16px;
    line-height: 1.5;
    padding-right: 0px !important;
  }
  .skills-note{
    margin-top: -30px;
    margin-bottom: 30px;
  }
}

@media (max-width: 480px) {
  .skills {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 15px;
  }

  .skills-title {
    font-size: 36px;
    text-align: center;
    margin-bottom: 35px;
  }

  .skills-icon-cloud {
    width: 70%; /* Increase size for smaller screens */
  }

  .skills-description {
    width: 90%;
    font-size: 14px;
    line-height: 1.4;
  }

  .skills-note{
    font-size: 14px;
  }
}
