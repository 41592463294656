.about {
  width: 100vw;
  height: 100svh !important;
  display: flex;
}

.about-left {
  flex: 0.5; /* Left side (polaroid section) takes up 45% */
  display: flex;
  justify-content: center;
  align-items: center;
}

.about-right {
  flex: 0.5; /* Right side (content section) takes up 55% */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start; /* Align content to the left */
}

.about-polaroid {
  width: 50%; /* Adjust to desired width */
  max-width: 300px;
  aspect-ratio: 3 / 4;
  background-color: white;
  padding: 15px 15px 0px; /* Adjust padding to make space for the magnet */
  border: 5px solid #fff;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
  transform: rotate(-3deg);
  position: relative; /* Position relative for absolute child (magnet) */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}

.magnet-img {
  width: 30px; /* Adjust the size of the magnet */
  height: auto;
  position: absolute;
  top: -25px; /* Adjust the position above the polaroid */
  left: 50%; /* Center it horizontally */
  transform: translateX(-50%); /* Correct centering */
}

.profile-pic {
  width: 100%;
  height: 75%;
  object-fit: cover;
  border-top: 5px solid #fff;
}


.profile-pic {
  width: 100%;
  height: 75%; /* The image stays at the bottom */
  object-fit: cover; /* Ensures the image fits well in the frame */
  border-top: 5px solid #fff;
}

.about-content {
  width: 80%; /* Right side content takes up 80% of the 55% */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; /* Center the content */
}

.about-title {
  font-size: 64px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #383839; /* Dark grey color */
  font-family: 'Permanent Marker', cursive; /* Use Permanent Marker for the title */
  text-align: left; /* Center the title */
  width: 100%; /* Ensure the title spans the full width */
}

.about-description {
  font-size: 20px;
  line-height: 1.6;
  color: #383839; /* Set description text to the same dark grey color */
  font-family: 'Open Sans', sans-serif; /* More standard, legible font */
  text-align: left; /* Left-aligned description */
  padding-right: 100px !important; /* Add padding from the right side */
}
strong{
  font-weight: 700;
}

@media (max-width: 1024px) {
  .about-title {
    font-size: 48px;
  }
  .about-description {
    font-size: 18px;
    padding-right: 50px !important;
  }
  .about-polaroid {
    padding: 5px 10px 0px; /* More padding at the top for white space */
  }
}


/* Stacked column layout for smaller screens */
@media (max-width: 768px) {
  .about {
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center align everything */
    justify-content: center;
    padding: 20px;
  }

  .about-title {
    font-size: 40px;
    text-align: center;
    margin-bottom: 60px;
    order: 1; /* Ensure title appears first */
    width: 90%; /* Ensure title is centered with respect to description width */
  }

  .about-polaroid {
    width: 35%; /* Set a reasonable width for smaller screens */
    max-width: 300px;
    padding: 10px 10px 0px; /* Maintain white space around polaroid */
    margin-bottom: 60px;
    order: 2; /* Polaroid comes second */
  }

  .about-description {
    width: 90%; /* Ensure the description fits nicely on small screens */
    text-align: left;
    font-size: 16px;
    line-height: 1.5;
    padding-right: 0px !important; /* Add padding on the right side */
    order: 3; /* Ensure description comes last */
  }
}

@media (max-width: 480px) {
  .about {
    flex-direction: column; /* Stack elements vertically */
    align-items: center; /* Center align everything horizontally */
    justify-content: center; /* Center align everything vertically */
    height: 100dvh; /* Full height of the viewport */
    padding: 15px;
  }

  .about-title {
    font-size: 36px;
    text-align: center;
    order: 1;
    width: 100%; /* Full width on smaller screens */
  }

  .about-polaroid {
    width: 35%; /* Set a reasonable width for smaller screens */
    max-width: 250px;
    padding: 10px 10px 0px; /* Maintain white space around polaroid */
    order: 2;
  }

  .about-description {
    width: 90%; /* Ensure the description fits nicely on small screens */
    text-align: left;
    font-size: 14px;
    line-height: 1.4;
    padding-right: 10px; /* Ensure padding from the right edge */
    order: 3;
    margin-bottom: 15px; /* Add space below the description */
  }
}

