.works {
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

/* Mimic the skills title */
.works-title {
  font-size: 64px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #383839;
  font-family: 'Permanent Marker', cursive; /* Same font as the other sections */
  text-align: left; /* Align text to the left */
  width: 100%; /* Full width */
  padding-left: 50%; /* Add padding to the left for alignment */
}

/* Container for polaroid items */
.polaroid-container {
  display: flex;
  flex-direction: column; /* Stack all polaroids vertically */
  gap: 30px; /* Add space between each polaroid */
  align-items: center; /* Center the polaroids horizontally */
}

.project-container {
  width: 100%;
  max-width: 1200px; /* Center the project and limit its width */
  margin: 0 auto; /* Center the project within its container */
}

@media (max-width: 1024px){
  .works-title {
    text-align: center;
    padding-left: 0;
    font-size: 48px; /* Reduce title size on smaller screens */
  }
}

/* Responsive design */
@media (max-width: 768px) {
  .works-title {
    font-size: 40px; /* Reduce title size on smaller screens */
  }

  .polaroid-container {
    gap: 20px; /* Smaller gap between items on smaller screens */
  }
}

@media (max-width: 480px){
  .works-title{
    font-size: 36px;
  }
}
