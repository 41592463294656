@import url('https://fonts.googleapis.com/css2?family=Permanent+Marker&family=Caveat&display=swap');

.homepage-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100svh !important;
  background-color: white;
  position: relative;
}

.paper-airplane-icon {
  position: absolute;
  top: 20%;
  left: 20%;
  cursor: pointer;
  opacity: 0;
  transition: opacity 0.8s ease-in-out;
  z-index: 10;
  width: 80px;
  height: 80px;
}

.markers-image {
  position: absolute;
  bottom: 20px; /* Adjust the bottom distance as needed */
  right: 40px; /* Adjust the right distance as needed */
  width: 200px; /* Set the width of the image */
  height: auto; /* Maintain the aspect ratio */
  opacity: 0; /* Start hidden, will fade in */
  transition: opacity 0.8s ease-in-out; /* Smooth fade-in effect */
  z-index: 5; /* Ensure it stays above background but below main content */
}

/* src/sections/Homepage/Homepage.css */

.volleyball-icon {
  position: absolute;
  top: 55%; /* Adjust starting top position */
  left: 7%; /* Position from the left */
  width: 100px; /* Size of the volleyball */
  height: 100px;
  opacity: 0; /* Start hidden, fades in later */
  transition: opacity 0.8s ease-in-out;
  cursor: pointer;
}

.smiley-icon {
  position: absolute;
  top: 75%;
  left: 55%;
  width: 64px;
  height: 64px;
  opacity: 0; /* Start hidden */
  transition: opacity 0.8s ease-in-out;
}


.volleyball-icon svg {
  fill: #383839; /* Match color with design */
}



.navbar {
  position: absolute;
  top: 20px;
  right: 40px;
  display: flex;
  gap: 24px;
  opacity: 0;
  transition: opacity 0.8s ease-in-out;
}

.tray-image {
  position: absolute;
  bottom: 0; /* Aligns the tray to the bottom of the viewport */
  left: 50%; /* Position the tray's left edge at the center of the container */
  transform: translateX(-50%); /* Shift it back by half its width to center */
  width: 45%; /* Make it take up 50% of the page width */
  height: auto; /* Maintain aspect ratio */
  opacity: 0; /* Start hidden, will fade in */
  transition: opacity 0.8s ease-in-out; /* Smooth fade-in effect */
  z-index: 5; /* Ensure it stays above background but below main content */
}

.sticky-note {
  width: 120px;
  height: 120px;
  min-width: 120px;
  min-height: 120px;
  background-color: #FEFF9C;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Caveat', cursive;
  font-size: 32px;
  text-decoration: none;
  color: black;
  transition: transform 0.2s, background 0.2s;
  position: relative;
  flex-shrink: 0;
}

.sticky-note:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 15%;
  background-color: #FBFC85;
}

.sticky-note:hover {
  transform: scale(1.05);
}

#vara-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 600px;
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: visible;
}

.text-container {
  opacity: 0;
  transition: opacity 1s ease-in-out;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: 'Permanent Marker', cursive;
  white-space: nowrap;
}

.title {
  font-size: 128px;
  color: #383839;
  margin: 0;
}

.subtitle {
  font-size: 40px;
  color: #383839;
  margin: 0;
}

@media (max-width: 1024px) {
  .title {
    font-size: 84px;
  }

  .subtitle {
    font-size: 32px;
  }

  .sticky-note {
    width: 100px;
    height: 100px;
    min-width: 100px;
    min-height: 100px;
    font-size: 24px;
  }

  .smiley-icon {
    width: 50px;
    height: 50px;
  }

  
  .paper-airplane-icon {
    width: 60px;
    height: 60px;
    top: 25%;
    left: 20%;
  }

  .markers-image {
    width: 160px; /* Set the width of the image */
    bottom: 48px;
  }

.volleyball-icon {
  position: absolute;
  top: 58%; /* Adjust starting top position */
  left: 7%; /* Position from the left */
  width: 70px; /* Size of the volleyball */
  height: 70px;
}

}

@media (max-width: 768px) {
  .title {
    font-size: 52px;
  }

  .subtitle {
    font-size: 24px;
  }

  .sticky-note {
    width: 80px;
    height: 80px;
    min-width: 80px;
    min-height: 80px;
    font-size: 20px;
  }

  .navbar {
    top: 16px;
    right: 32px;
    gap: 16px;
  }

  .paper-airplane-icon {
    width: 40px;
    height: 40px;
    top: 30%;
    left: 15%;
  }

  .smiley-icon{
    height: 40px;
    width: 40px;
  }

  .markers-image {
    right: 16px; /* Adjust the right distance as needed */
    width: 120px; /* Set the width of the image */
  }
  /* src/sections/Homepage/Homepage.css */

.volleyball-icon {
  position: absolute;
  top: 61%; /* Adjust starting top position */
  left: 7%; /* Position from the left */
  width: 50px; /* Size of the volleyball */
  height: 50px;
}
}

@media (max-width: 480px) {
  .title {
    font-size: 48px;
  }

  .subtitle {
    font-size: 24px;
  }

  .navbar {
    position: absolute;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    gap: 16px;
    display: flex;
    justify-content: center; /* Center horizontally */
    align-items: center; /* Center vertically if needed */
  }

  .sticky-note {
    width: 70px;
    height: 70px;
    min-width: 60px;
    min-height: 60px;
    font-size: 20px;
  }

  .paper-airplane-icon {
    width: 40px;
    height: 40px;
    top: 35%;
    left: 30%;
  }

  .volleyball-icon {
    width: 40px;
    height: 40px;
    top: 59%;
  }
  
  .markers-image{
    bottom: 124px;
  }

  .smiley-icon {
    top: 88%;
    left: 55%;
    width: 40px;
    height: 40px;
  }
  
}
