footer {
  width: 100%;
  background-color: white; /* Whiteboard theme */
  padding: 20px 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
}

/* Static black line */
.footer-top-line {
  width: 100%; /* Full width minus padding */
  height: 1px; /* Fixed thickness */
  background-color: #ddd;
  border-radius: 20px 20px 20px 20px;
  position: absolute;
  top: 0;
  z-index: 1;
  margin: 0 auto; /* Center the line */
}

.social-logo {
  width: 20px; /* Adjust size of the logo */
  height: 20px;
  margin-left: 10px; /* Add spacing between text and logo */
}

/* Footer content container */
.footer-columns {
  display: flex;
  justify-content: center; /* Center the entire group of columns */
  width: calc(100% - 40px); /* Full width minus padding */
  max-width: 1200px;
  margin-top: -20px;
  flex-wrap: nowrap; /* Ensure two columns always stay side by side */
  gap: 40px; /* Default gap for desktop */
}

/* Footer columns */
.footer-column {
  flex: 1;
  margin: 10px;
  text-align: left; /* Left align the text */
}

.footer-column h3 {
  margin-bottom: 10px;
  font-family: 'Permanent Marker', cursive; /* Permanent Marker font */
  font-size: 24px;
  color: #383839;
}

.footer-column ul {
  list-style-type: none;
  padding: 0;
}

/* Sticky Note Button Styles */
.footer-sticky-note {
  background-color: #FEFF9C; /* Sticky note color */
  border: none;
  padding: 10px 20px;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
  text-align: left;
  font-family: 'Caveat', cursive; /* Handwritten style font */
  font-size: 20px;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.3); /* Add shadow for sticky note effect */
  transition: all 0.3s ease;
  width: 150px; /* Example width */
  height: 50px; /* Example height */
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: black !important; /* Ensure the text is always black */
}

.footer-sticky-note::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 16px; /* Darker left part to simulate "stuck" area */
  height: 100%;
  background-color: #FBFC85; /* Darker color for the sticky effect */
  z-index: 1; /* Ensure it's below the content of the sticky note */
}

.footer-sticky-note:hover {
  box-shadow: 4px 4px 12px rgba(0, 0, 0, 0.5);
  transform: translateY(-3px);
}

/* Social links */
.footer-column ul li {
  margin-bottom: 16px;
  font-family: 'Asap', sans-serif;
}

.footer-column ul li a {
  color: black;
  text-decoration: none;
}

.footer-column ul li a:hover {
  text-decoration: none; /* Removed underline on hover */
}

/* Circular button with an up arrow positioned at the bottom right */
.footer-top-button {
  position: absolute;
  bottom: 20px; /* Position it near the bottom of the footer */
  right: 20px; /* Position it near the right side of the footer */
  padding-right: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-top-button button {
  width: 60px;
  height: 60px;
  background-color: #383839;
  color: white;
  border: none;
  border-radius: 50%; /* Circular shape */
  font-size: 24px; /* Arrow size */
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s ease;
}

.footer-top-button button::before {
  content: '↑'; /* Up arrow icon */
  font-size: 28px;
  color: white;
}

.footer-top-button button:hover {
  background-color: #333;
}

/* Footer message and copyright */
.footer-message {
  font-family: 'Permanent Marker', cursive;
  font-size: 14px;
  color: #383839;
  margin-top: 20px;
}

.footer-copyright {
  font-family: 'Permanent Marker', cursive;
  font-size: 12px;
  color: #383839;
}

/* Force two columns on all screen sizes */
@media (max-width: 768px) {
  .footer-columns {
    flex-direction: row; /* Keep the two columns side by side */
    justify-content: space-between;
  }

  .footer-sticky-note {
    width: 120px; /* Adjust width */
    height: 40px; /* Adjust height */
    font-size: 18px;
  }

  .footer-sticky-note a {
    color: black !important; /* Ensure the text is always black */
    text-decoration: none !important; /* Remove the underline */
  }
  
  .footer-sticky-note a:hover {
    color: black !important; /* Ensure it stays black on hover */
  }
  
}

@media (max-width: 480px) {

  footer {
    padding: 100px 40px;
  }
  .footer-columns {
    margin-top: -100px;
    flex-direction: row; /* Ensure it's two columns on small screens */
    gap: 30px;
    justify-content: center; /* Center the entire group of columns */

  }
  .footer-column h3 {
    font-size: 20px;
  }
  .footer-sticky-note {
    width: 110px; /* Adjust width */
    height: 35px; /* Adjust height */
    font-size: 16px;
  }
  .footer-top-button {
    
    bottom: 20px; /* Position it near the bottom of the footer */
    right: 20px; /* Position it near the right side of the footer */
    padding-right: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }


}
