/* Main layout for large screens (1024px and above) */
.contact {
  display: flex;
  flex-direction: row-reverse; /* Place canvas on the right and form on the left */
  width: 100vw;
  height: 100svh !important;
  padding: 20px;
  box-sizing: border-box;
}

.contact-left {
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.canvas-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.contact-canvas {
  border: 2px solid #383839;
  background-color: white;
  width: 400px;
  height: 400px;
  touch-action: none; /* This prevents scroll when interacting with the canvas */
}

.canvas-wrapper {
  display: flex;
  flex-direction: column; /* For large screens, stack canvas and controls vertically */
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.contact-controls {
  display: flex;
  flex-direction: row; /* For large screens, controls will be in a row below the canvas */
  gap: 10px;
}

.contact-controls button {
  padding: 10px 15px;
  background-color: #383839;
  color: white;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-controls button:hover {
  background-color: #4a4a4a;
}

.contact-right {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 20px;
}

.contact-right h2 {
  font-size: 64px;
  font-family: 'Permanent Marker', cursive;
  color: #383839;
}

.contact-form {
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 80%;
}

.contact-form input,
.contact-form textarea {
  padding: 10px;
  border: 1px solid #ccc;
  font-size: 16px;
}

.contact-form textarea {
  resize: none;
  height: 150px;
}

.contact-form button {
  padding: 10px 15px;
  background-color: #383839;
  color: white;
  font-size: 16px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.contact-form button:hover {
  background-color: #4a4a4a;
}

.form-subtitle {
  font-size: 12px;
  color: #555;
  text-align: center;
  margin-top: -5px;
  font-family: 'Open Sans', sans-serif;
}

.color-palette {
  display: flex;
  gap: 10px;
  margin-top: 10px;
}

.color-box {
  width: 30px;
  height: 30px;
  border: 1px solid #ccc;
  cursor: pointer;
  transition: transform 0.2s ease;
}

.color-box:hover {
  transform: scale(1.1);
}

/* Media query for screens 1024px or smaller */
@media (max-width: 1024px) {
  .contact {
    flex-direction: column; /* Stack sections vertically */
    align-items: center;
    justify-content: center;
    height: auto;
    padding: 20px 10px;
  }

  .contact-right {
    order: 1;
    width: 100%;
    margin-bottom: 20px;
    align-items: center;
    padding-left: 0;
  }

  .contact-form {
    width: 70%;
    height: 270px;
  }

  .contact-right h2 {
    font-size: 48px;
    text-align: center;
  }

  .contact-left {
    order: 2;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .canvas-wrapper {
    display: flex;
    flex-direction: row; /* For small screens, canvas and controls side by side */
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  .contact-canvas {
    width: 250px;
    height: 250px;
  }

  .contact-controls {
    flex-direction: column; /* Stack controls vertically for smaller screens */
    margin-left: 20px;
  }
}

  @media (max-width: 768px) {
    .contact-right h2 {
      font-size: 40px;
    }
    .contact-canvas{
      height: 200px;
      width: 200px;
    }
    
  }

  @media (max-width: 480px) {
    .contact-right h2 {
      margin-top: -40px;
      font-size: 36px;
      width: 200px;
    }
    .contact-canvas{
      height: 180px;
      width: 180px;
    }
  }


