/* General Styles */
html, body {
    margin: 0;
    padding: 0;
    height: 100%;
    overflow-x: hidden;
    scroll-behavior: smooth;
}

/* App Container */
.app-container {
    height: 100vh;
    width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;
}

/* Enable scroll snapping for screens other than exactly 480px */
@media (max-width: 479px), (min-width: 481px) {
    .app-container {
        scroll-snap-type: y mandatory; /* Vertical snapping enabled */
    }
}

/* Disable snapping exactly at 480px width */
@media (width: 480px) {
    .app-container {
        scroll-snap-type: none; /* Disable snapping */
    }
}

/* Section Styles */
.section {
    scroll-snap-align: start;
    min-height: 100vh; /* Ensure sections fill viewport */
    width: 100vw;
}

/* Works Section - No Snap */
#works {
    min-height: 100vh;
    overflow-y: auto; /* Ensure it scrolls internally if needed */
}

/* Contact Section */
#contact {
    min-height: 100vh;
    height: auto;
    overflow-y: auto; /* Ensure it scrolls internally if needed */
}

/* Footer - Ensure No Snapping */
#footer {
    scroll-snap-align: none; /* Prevent snapping for footer */
    height: auto;
    width: 100%; /* Ensure footer spans the full width */
}

/* Prevent extra scroll within canvas interactions */
.contact-canvas {
    touch-action: auto; /* Allow normal touch behavior */
    user-select: none; /* Prevent text selection */
}

/* Ensure all sections align properly */
.section * {
    max-width: 100vw;
    box-sizing: border-box;
}
