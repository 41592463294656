/* Container for the whole project card */
.project-container {
  display: flex;
  width: 100%;
  max-width: 1200px;
  margin: 20px auto;
  padding: 20px;
  box-sizing: border-box;
  align-items: flex-start;
  justify-content: space-between;
}

/* Make the link fill the polaroid section */
.project-link {
  display: block;
  text-decoration: none;
}

/* Left column for the Polaroid */
.project-polaroid {
  width: 300px;
  background-color: white;
  border: 15px solid #fff;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.3);
  text-align: center;
  display: flex;
  flex-direction: column;
  transition: transform 0.3s ease; /* Smooth scaling on hover */
  cursor: pointer; /* Show pointer to indicate it's clickable */
  margin-left: 100px;
}

.project-polaroid:hover {
  transform: scale(1.05); /* Grow the polaroid on hover */
}

.project-polaroid-image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-bottom: 5px solid #fff;
}

.project-polaroid-title {
  font-family: 'Caveat', cursive;
  font-size: 24px;
  font-weight: 700;
  padding: 15px 10px;
  color: #333;
}

/* Right column for the description */
.project-details {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.project-description {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 1.6;
  color: #555;
  margin-bottom: 20px;
}

.project-sticky-notes {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.project-sticky-note {
  width: 120px;
  height: 120px;
  padding: 10px;
  font-size: 18px;
  font-family: 'Caveat', cursive;
  text-decoration: nowrap;
  color: black;
  background-color: var(--sticky-color, #f0f0f0);
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

/* Add a darker rectangle at the top to represent the sticky part */
.project-sticky-note::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 15%;
  background-color: rgba(0, 0, 0, 0.025);
}

@media (max-width: 1024px) {
  .project-container {
    flex-direction: column;
    align-items: center;
  }

  .project-polaroid,
  .project-details {
    width: 400px;
    max-width: 100%;
    margin-left: 0;
  }

  .project-details {
    padding: 20px 0;
  }

  .project-sticky-note {
    width: 100px;
    height: 100px;
    font-size: 16px;
  }

  .project-sticky-notes {
    justify-content: left;
  }
}

@media (max-width: 768px) {
  .project-container {
    flex-direction: column;
    align-items: center;
  }

  .project-polaroid,
  .project-details {
    width: 300px;
    max-width: 100%;
  }

  .project-details {
    padding: 20px 0;
  }

  .project-sticky-notes {
    justify-content: left;
  }
}
