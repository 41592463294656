/* Container for the tape and the note */
.menu-notepaper-container {
  position: fixed;
  top: 10px;
  left: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1000;
  perspective: 1000px; /* Add perspective for z-axis animation */
}

/* Design for the tape */
.menu-tape {
  width: 60px;  /* Adjust the size of the tape as needed */
  height: auto;
  margin-bottom: -10px; /* Overlap the note slightly */
  z-index: 2; /* Make sure the tape is above the note */
  pointer-events: none;  /* Prevent interaction */
}

/* Design for the notebook paper */
.menu-notepaper {
  width: 100px;
  height: 100px;
  margin-top: -20px;
  background-color: #fdfdfd; /* Paper white background */
  border: 2px solid #e0e0e0; /* Light border to mimic paper edges */
  box-shadow: 0px 10px 30px rgba(0, 0, 0, 0.2); /* Add depth with shadow directly on paper */
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'Caveat', cursive;
  font-size: 32px;
  color: black;
  position: relative;
  z-index: 1;
  background-image: linear-gradient(to bottom, transparent 24px, #d3d3d3 25px); /* Lined paper effect */
  background-size: 100% 25px;
  padding: 10px;
  transform-origin: top center;
  transition: transform 0.3s ease;
  cursor: pointer;
}

.menu-notepaper:hover {
  transform: scale(1.05);  /* Slightly grow the note on hover */
}

/* Shadow under the note, fixed at the landing position */
.menu-shadow {
  position: absolute;
  top: 30px;  /* Ensure shadow is underneath where the note will land */
  width: 100px;
  height: 100px;
  background-color: rgba(0, 0, 0, 0.2); /* Shadow color */
  border-radius: 10px; /* Rounded corners to match the note */
  z-index: 0;  /* Make sure shadow is behind the note */
  transform-origin: center;
  box-shadow: 0px 30px 60px rgba(0, 0, 0, 0.2); /* Create a strong shadow underneath */
  pointer-events: none;  /* Disable interaction */
}

/* Pull-out menu */
/* Pull-out menu */
.menu-pullout {
  position: fixed;
  top: 0;
  left: 0;
  width: 33%;  /* Takes up 33% of the screen */
  height: 100vh;  /* Full height of the viewport */
  background-image: url('../assets/Notebook.jpg');
  background-size: 100% 100%;  /* Ensure the image covers the entire container */
  background-position: right top !important;  /* Always anchor the right side */
  background-repeat: no-repeat;  /* Prevent image repetition */
  box-shadow: 5px 0 15px rgba(0, 0, 0, 0.3);  /* Shadow for depth */
  transform: translateX(-100%);  /* Hidden by default */
  z-index: 9999;  /* Lower than 1000 to keep it below the sticky note */
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;  /* Hide anything that exceeds the bounds of the container */
}






/* Shift all menu options down by adding padding to the ul */
.menu-pullout ul {
  list-style: none;
  padding: 100px 20px 20px;  /* Add top padding to shift all options down */
  margin: 0;
  flex: 1;
}

.menu-pullout ul li {
  margin-bottom: 20px;
}

.menu-pullout ul li a {
  text-decoration: none;
  font-family: 'Caveat', cursive;
  font-size: 40px;
  color: black;
  display: flex;  /* Flex layout to align text and paperclip */
  align-items: center;
  padding: 10px 15px;
  transition: transform 0.3s ease, color 0.3s ease;
}

.menu-pullout ul li a:hover {
  transform: translateX(10px);  /* Shift the text to the right on hover */
}

/* Style for the paperclip */
.menu-pullout ul li img.paperclip {
  z-index: 9999999999;
  margin-left: 10px;  /* Space between text and paperclip */
  width: 64px;
  height: auto;
}

/* "X" button to close the menu */
.menu-close-btn {
  font-family: 'Caveat', cursive;
  font-size: 32px;
  position: absolute;
  top: 20px;
  right: 70px;
  background: none;
  border: none;
  cursor: pointer;
  color: black;
  transition: transform 0.3s ease;
}

.menu-close-btn:hover {
  transform: scale(1.2);  /* Slightly grow the X button on hover */
}

.active {
  font-weight: bold;
}

/* Dimmed overlay when the menu is open */
.dimmed-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);  /* Black with 50% opacity for dimming effect */
  z-index: 9998;  /* Just below the menu pullout */
  pointer-events: none;  /* Make it non-interactive */
  transition: opacity 0.3s ease;  /* Smooth transition */
  opacity: 0;  /* Initially hidden */
}

.dimmed-overlay.active {
  opacity: 1;  /* Fully visible when active */
}


/* Responsive adjustments */
@media (max-width: 1024px) {
  .menu-notepaper {
    width: 80px;
    height: 80px;
    font-size: 24px;
  }

  .menu-shadow {
    width: 80px;
    height: 80px;
  }

  .menu-pullout {
    width: 40%; /* Adjust for smaller screens if needed */
  }

  .menu-pullout ul li a {
    font-size: 36px;
  }

  .menu-pullout ul li img.paperclip {
    width: 48px;
  }
}

@media (max-width: 768px) {
  .menu-notepaper {
    width: 60px;
    height: 60px;
    font-size: 20px;
  }
  .menu-shadow {
    width: 60px;
    height: 60px;
  }

  .menu-pullout {
    width: 50%; /* Adjust for smaller screens if needed */
  }

  .menu-pullout ul li a {
    font-size: 32px;
  }

  .menu-pullout ul li img.paperclip {
    width: 40px;
  }
}

@media (max-width: 480px) {
  .menu-notepaper {
    width: 50px;
    height: 50px;
  }
  .menu-shadow {
    width: 50px;
    height: 50px;
  }
  .menu-pullout {
    width: 70%; /* Adjust for very small screens if needed */
  }

  .menu-pullout ul li a {
    font-size: 28px;
  }

  .menu-pullout ul li img.paperclip {
    width: 36px;
  }

  .menu-pullout ul li a:hover {
    transform: none;
  }
}
